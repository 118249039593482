import {useMutation} from '@apollo/client';
import React, {useCallback, useState} from 'react';
import Button from '../../components/form/button';
import Input from '../../components/form/input';
import Select from '../../components/form/select';
import Section from '../../components/section';
import Textarea from '../../components/form/textarea';
import Layout from '../../components/layout';
import Message from '../../components/messages';
import {CONTACT_FORM_SUBMIT} from '../../graphql/mutations';
import {isValidEmail} from '../../helpers';
import {emailOptions} from '../../helpers/constants';
import './styles.scss';

const ContactUs = () => {
  //-----------------State----------------------
  const [formFields, setFormFields] = useState({
    firstName: '',
    lastName: '',
    email: '',
    comment: '',
    subject: '',
  });
  const [error, setError] = useState('');
  const [required, setRequired] = useState(false);
  const [success, setSuccess] = useState(false);
  // const [emailTo, setEmailTo] = useState({
  //   label: 'Please select a department',
  //   value: '',
  // });

  //-------------------Mutations---------------------
  const [contactMutation, {loading}] = useMutation(CONTACT_FORM_SUBMIT);
  //-------------------Functions---------------------
  const handleChangeFields = useCallback(
    (e: any) => {
      setError('');
      setFormFields({...formFields, [e.target.name]: e.target.value});
    },
    [formFields],
  );

  const getExistingResult = useCallback(async () => {
    try {
      const {data} = await contactMutation({
        variables: {
          name: formFields.firstName + ' ' + formFields.lastName,
          email: formFields.email.trim(),
          subject: `Hexospark: ${formFields.subject}`,
          message: formFields.comment,
          // emailTo: emailTo.value.trim(),
          emailTo: 'support@hexospark.com',
          productType: 'Hexospark',
        },
      });
      if (!data) return;
      const {
        contactFormSubmit: {error, errorMessage},
      } = data;
      if (error) {
        setError(errorMessage.replaceAll('_', ' ').toLowerCase().slice(1));
      } else {
        setFormFields({
          firstName: '',
          lastName: '',
          email: '',
          comment: '',
          subject: '',
        });
        setSuccess(true);
        const time = setTimeout(() => {
          setSuccess(false);
          clearTimeout(time);
        }, 10000);
      }
    } catch (e) {
      setError('Something went wrong');
    }
  }, [contactMutation, formFields]);

  const handleClick = useCallback(() => {
    if (loading) return;

    const isComplete =
      Object.values(formFields).filter((item: string) => !item).length || !isValidEmail(formFields.email.trim());
    // || !emailTo.value.trim();
    if (isComplete) {
      if (
        !formFields.firstName.trim() ||
        !formFields.lastName.trim() ||
        !formFields.email.trim() ||
        !formFields.comment.trim() ||
        !formFields.subject.trim()
      ) {
        setError('Please complete the fields');
        setRequired(true);
        return;
      }
      if (!isValidEmail(formFields.email.trim())) {
        setError('Email is not valid');
        setRequired(true);
        return;
      }
      // if (!emailTo.value.trim()) {
      //   setError('Please select a department');
      //   setRequired(true);
      //   return;
      // }
    } else {
      setRequired(false);
      getExistingResult();
    }
  }, [loading, formFields, getExistingResult]);

  return (
    <Layout>
      <Section pageheroPading className="contact-us-page mx-auto" lgPd>
        <div className="container">
          <div className="breadcrumb_content text-center mb-5">
            <h1 className="f_size_48 f_600 t_color4 l_height54 text-center">Feel free to contact us</h1>
          </div>
        </div>
        <div className="form-block row mx-0">
          <div className="col-12 col-md-6 mb-3">
            <label htmlFor="firstName">
              First name <span>*</span>
            </label>
            <Input
              name="firstName"
              id="firstName"
              value={formFields.firstName}
              onChange={handleChangeFields}
              placeholder="First name"
              error={!formFields.firstName && required ? 'error' : ''}
            />
          </div>
          <div className="col-12 col-md-6 mb-3">
            <label htmlFor="lastName">
              Last name <span>*</span>
            </label>
            <Input
              name="lastName"
              id="lastName"
              value={formFields.lastName}
              onChange={handleChangeFields}
              placeholder="Last name"
              error={!formFields.lastName && required ? 'error' : ''}
            />
          </div>
          <div className="col-12 mb-3">
            <label htmlFor="email">
              Your email <span>*</span>
            </label>
            <Input
              className="email-input"
              name="email"
              type="email"
              id="email"
              value={formFields.email}
              onChange={handleChangeFields}
              placeholder="example@gmail.com"
              error={required && (!formFields.email || !isValidEmail(formFields.email)) ? 'error' : ''}
            />
            {/* {required && (!formFields.email || !isValidEmail(formFields.email)) && (
                <div className="error-text">Email is not valid</div>
              )} */}
          </div>
          {/* <div className="col-12 mb-3">
                <label htmlFor="department">
                  Contact department <span>*</span>
                </label>
                <Select
                  options={emailOptions}
                  value={emailTo}
                  onChange={setEmailTo}
                  className={!emailTo.value ? 'disabled-text' : ''}
                  placeHolder="Please select a department"
                  empty={!!error && !emailTo.value.trim()}
                />
              </div> */}
          <div className="col-12 mb-3">
            <label htmlFor="lastName">
              Subject <span>*</span>
            </label>
            <Input
              name="subject"
              id="subject"
              value={formFields.subject}
              onChange={handleChangeFields}
              placeholder="Subject"
              error={!formFields.subject && required ? 'error' : ''}
            />
          </div>
          <div className="col-12 mb-3">
            <label htmlFor="comment">
              Your comment <span>*</span>
            </label>
            <Textarea
              value={formFields.comment}
              onChange={handleChangeFields}
              placeholder="Your comment"
              rows={8}
              disableResize
              error={!formFields.comment && required}
              name="comment"
            />
          </div>
          <div className={`position-relative col-12 text-center ${error || success ? 'visible' : 'invisible'}`}>
            <Message
              type={success ? 'success' : 'error'}
              text={success ? 'Your message has been successfully sent!' : error}
            />
          </div>
          <div className="col-12 text-center mb-3">
            <Button btnText="Send message" width="230" onClick={handleClick} loading={loading} />
          </div>
        </div>
      </Section>
    </Layout>
  );
};

export default ContactUs;
